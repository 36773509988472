<template>
  <div class="container4">
    <div class="leftmap">
      
      <div class="plugin">
          <div :class="{'pbtn':true, 'activebtn':'vector'=== currentMapLayer.type}" @click="toggleMapType('vector')">矢量地图</div>
          <div :class="{'pbtn':true, 'activebtn':'satellite'=== currentMapLayer.type}" @click="toggleMapType('satellite')">影像地图</div>
      </div>
      <l-map
        ref="map"
        style="width: 100%; height: 100%; z-index: 10"
        :zoom="zoom"
        :center="center"
      >
         <l-tile-layer :url="currentMapLayer.url" ></l-tile-layer>
         <l-tile-layer :url="currentMapLayer.texturl" :attribution="currentMapLayer.attribution"></l-tile-layer>

   

        <!-- 周边地块的多边形 -->
        <l-polygon v-for="polygon in polygonPath"  :lat-lngs="polygon.path" 
         :fill-color="polygon.filecolor" :fill-opacity="polygon.fillopacity" 
          :visible="polygon.hide"
          :color="polygon.filecolor"></l-polygon>
        <!-- <l-marker
          v-for="item in areaCountData"
          :key="item.id"
          :lat-lng="[item.lat_gaode, item.lng_gaode]"
          @click="toZoom({lat: item.lat_gaode, lng: item.lng_gaode})"
         
        >
          <l-icon :icon-anchor="staticAnchor">
            <div class="yuan">
              {{ item.count }}
            </div>
          </l-icon>
        </l-marker> -->

        <!-- 周边地块的点 -->
        <l-marker
        
          v-for="item in markerdata"
          :key="item.id"
          :icon="icon(item.usecate)"
          :lat-lng="[item.lat_gaode, item.lng_gaode]"
          :offset="[8, 0]"
          :visible = "item.hide"
        >
          <!-- permanent: true // 如果想要提示永久显示，可以设置为true -->
          <l-tooltip
            :options="{
              permanent: true,
              interactive: true,
              offset: [4, -16],
              className: 'color' + item.usecate,
            }"
           
          >
            <div class="title">
              {{
                item.locationnum ||
                item.noticenum ||
                item.locationname ||
                item.location
              }}
            </div>
          </l-tooltip>
          <l-popup   >
            <div class="detailbox">
              <div class="tltlecon">地块详情</div>
              <div class="line">
                <div class="left">省/市/区县</div>
                <div class="right">{{ item.ssq }}</div>
              </div>
              <div class="line">
                <div class="left">公告号</div>
                <div class="right">{{ item.noticenum }}</div>
              </div>
              <div class="line">
                <div class="left">宗地编号</div>
                <div class="right">{{ item.locationnum }}</div>
              </div>
              <div class="line">
                <div class="left">用途</div>
                <div class="right">{{ item.usecatename }}</div>
              </div>
              <div class="line">
                <div class="left">面积(㎡)</div>
                <div class="right">{{ item.acreage }}</div>
              </div>
              <div class="line">
                <div class="left">建筑面积(m*)</div>
                <div class="right">{{ item.buildacreage }}</div>
              </div>
              <div class="line">
                <div class="left">容积率</div>
                <div class="right">{{ item.volume }}</div>
              </div>

              <div class="line">
                <div class="left">竟得单位</div>
                <div class="right">{{ item.company }}</div>
              </div>
              <div class="line">
                <div class="left">挂牌价格</div>
                <div class="right">{{ item.totalsp }}</div>
              </div>
              <div class="line">
                <div class="left">成交总价</div>
                <div class="right">{{ item.tradeprice }}</div>
              </div>
              <div class="line">
                <div class="left">地块位置</div>
                <div class="right">{{ item.location }}</div>
              </div>
              <div class="line">
                <div class="left">成交日期</div>
                <div class="right">{{ item.tradedate }}</div>
              </div>
              <div class="line">
                <div class="left">楼面价</div>
                <div class="right">{{ item.floorprice }}</div>
              </div>
              <div class="line">
                <div class="left">溢价率</div>
                <div class="right">{{ item.overrage }}</div>
              </div>
              <div class="more">
                <!-- <div class="btn" @click="tozogndi(item.id)">查看更多</div> -->
              </div>
            </div>
          </l-popup>
        </l-marker>



     <!-- 当前地块点 -->
        <l-marker
          v-if="detail.lat_gaode && detail.lng_gaode"
          :name="detail.name"
          :icon="icon(detail.usecate)"
          :lat-lng="[detail.lat_gaode, detail.lng_gaode]"
          :offset="[0, 20]"
        >
          <l-tooltip
            :options="{
              permanent: true,
              interactive: true,
              offset: [4, -16],
              className: 'color' + detail.usecate,
            }"
           
          >
            <div class="currenttitle">
              {{
                detail.locationnum ||
                detail.noticenum ||
                detail.locationname ||
                detail.location
              }}
            </div>
          </l-tooltip>
          
        </l-marker>
        <!-- 当前地块的多边形 -->
        <l-polygon
          v-for="item in currentPolygonpath"
          :key="item.id"
          :lat-lngs="item.path"
          :color="item.strokeColor"
          :fill-color="item.filecolor"
          :fill-opacity="1"
        >
        
        </l-polygon>

        
      </l-map>

 
    </div>

   
  </div>
</template>

<script>



import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LPolyline,
  LTooltip,
  LPolygon,
  LIcon,
} from "vue2-leaflet";
import { vectorMapLayer,satelliteMapLayer,leafletmapurl,leafletmaptexturl,leafletmapyunxuanurl, isgaode } from "@/settings.js";

import { latLng,icon } from "leaflet";


import { getDetail,aroundLand } from "@/api/website/collect.js";


export default {
  name: "FrontendPcBigdataLandlayout",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LPolyline,
    LPolygon,
    LIcon,
    LTooltip,
  },

 
  data() {
    return {
      polygonPath:[],
      markerdata: [],
      currentPolygonpath: [],
      crs: L.CRS.EPSG3857, // 使用的是EPSG:3857坐标系
      url: leafletmapurl,
      attribution: "",
       //矢量地图对象
      vectorMapLayer:vectorMapLayer ,
      //卫星地图数据对象
      satelliteMapLayer: satelliteMapLayer,
      //当前地图图层
      currentMapLayer:{url:'',texturl:'',attribution:'',type:'vector'},
      staticAnchor: [16, 37],
      center: [0, 0],
      zoom: 14,
      isIndeterminate: true,
      id: 0,
      map: {},
      detail: {},
      mapdata:[],
    };
  },

  watch: {
    mapdata: {
      handler(nv, ov) {
        this.markerdata = nv
        this.handerPolygondata(nv);

      },
      deep: true,
    },


  },

  computed: {


  },

  mounted() {

    this.getMapDetail().then((res) => {
        this.getAroundLand()
    });
  },
  
  created() {
     this.id = this.$route.params.id;
     this.currentMapLayer = this.vectorMapLayer;
     this.currentMapLayer.type = 'vector'
  },


  methods: {
    handerPolygondata(polygondata) {
      let polygonpath = [];
      // this.handleListData(polygondata);

      const pointobj = polygondata.map((item) => {
      const allset = item.set_gaode; //所有多边形
      if (item.set_gaode) {
        const setObj = JSON.parse(allset);
        const rowpolygonpath = setObj.map((itemSingle) => {
          // data 是一个多变形的数据
          // 处理一个多变形的方法
          const singlepath = this.handleSingleData(item.id, itemSingle);
          polygonpath.push(singlepath);
        });
      }
    });

   
    this.polygonPath = polygonpath;
    setTimeout(() => {
      this.zoom = 15;
    }, 1000);
  },

    //处理右侧列表数据
  // handleListData(data) {
  //   console.log("data---", data);
  //   const list = [];
  //   data.map((item, index) => {
  //     if (index < 100) {
  //       item.title =
  //         item.locationnum ||
  //         item.locationname ||
  //         item.noticenum ||
  //         item.location;
  //       item.checked = true;
  //       list.push(item);
  //     }
  //   });
    // console.log('list',list)
  //   this.rightList = list;
  // },



    getAroundLand(){
      const map = this.$refs.map.mapObject; // 获取地图实例
      const bounds = map.getBounds(); // 获取当前视图的边界

      // 如果需要经纬度值，可以使用bounds对象的_northEast和_southWest属性
      const northEast = bounds._northEast;
      const southWest = bounds._southWest;
      //获取边界条件
      const params = {
        ll_lng: southWest.lng, // 经度 左下角
        ll_lat: southWest.lat, // 纬度 左下角
        ru_lng: northEast.lng, // 经度 右上角
        ru_lat: northEast.lat, // 纬度 右上角
        id:this.id,
        is_gaode:isgaode,
      };

      aroundLand(params).then((res) => {
          this.mapdata = res.data
      })
    },
     toggleMapType(type) {
      if (type === 'vector') {
        this.currentMapLayer = this.vectorMapLayer;
        this.currentMapLayer.type = 'vector'
      } else if (type === 'satellite') {
        this.currentMapLayer = this.satelliteMapLayer;
        this.currentMapLayer.type = 'satellite'
      }
    },
    icon(num) {
      if (num == null || num == "") {
        return icon({
          iconUrl: require("@/assets/opcity.png"),
          iconSize: [32, 37],
          iconAnchor: [16, 37],
        });
      } else {
        return icon({
          iconUrl: require("@/assets/type" + num + ".png"),
          iconSize: [32, 37],
          iconAnchor: [16, 37],
        });
      }
    },


    




 

    getMapDetail() {
      var that = this;
      return new Promise(function (resolve, reject) {
        getDetail({ id: that.id, is_gaode:isgaode }).then((res) => {
          that.detail = res.data;
          that.handercurrentPolygondata(res.data.id, res.data.set_gaode);
          that.center = [res.data.lat_gaode, res.data.lng_gaode];
          return resolve(res.data);
        });
      });
    },

    handercurrentPolygondata(id, allset) {
      let currentPolygonpath = [];
      const setObj = JSON.parse(allset);
      console.log("setObj", setObj);
      const rowcurrentPolygonpath = setObj.map((itemSingle) => {
        // data 是一个多变形的数据
        // 处理一个多变形的方法
        const singlepath = this.handleSingleData(id, itemSingle);
        currentPolygonpath.push(singlepath);
      });
      this.currentPolygonpath = currentPolygonpath;
    },

    handleSingleData(id, data) {
      let obj = {
        id: id,
        path: [],
        filecolor: "#000",
        strokcolor: "#409EFF",
        weight: this.strokeWeight,
        strokeopacity: this.strokeOpacity,
        fillopacity: this.fillOpacity,
      };
      const pointobj = data.lnglat.split(";");
      const pointArr = pointobj.map((point) => {
        const lnglat = point.split(",");
        return [parseFloat(lnglat[1]), parseFloat(lnglat[0])];
      });
      obj.path = pointArr;
      obj.filecolor = data.color;

      return obj;
    },

  },
};
</script>

<style lang="scss"  scoped>

.title {
  font-size: 12px;
  width: auto;
}
.currenttitle{
  font-size:14px;
  font-weight:bold;
  color:red;
}

.detailbox {
  display: flex;
  flex-direction: column;
  .tltlecon {
    font-size: 16px;
    font-weight: bold;
    margin: 10px;
  }
  .line {
    display: flex;
    flex-direction: row;
    line-height: 20px;

    .left {
      font-size: 12px;
      width: 30%;
      margin-left: 1%;
      height: 100%;
      overflow: hidden;
      background: #fff;
      padding-left: 5px;
      display: flex;
      flex-direction: column;
    }
    .right {
      font-size: 12px;
      width: 70%;
      height: 100%;
      overflow: hidden;
      background: #fff;
      padding-left: 5px;
      display: flex;
      flex-direction: column;
    }
  }
  .more {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 12px;
    color: #28a7e1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn {
      width: 200px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 12px;
      color: #fff;
      background: #28a7e1;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}

.plugin{
  height:0px;
  width:120px;
  display: flex;
  background: #fff;
  position: relative;
  float: left;
  top:20px;
  z-index: 1000;
  left:60px;
  .pbtn{
    width:60px;
    height:20px;
    color:#000;
    font-weight: bold;
    line-height: 20px;
    background: #fff;
    border: solid 1px #FFF;
    cursor: pointer;
  }
  .activebtn{
    color:#1384cf;
    border: solid 1px #1384cf;
  }
}


/* 隐藏国企以及版权标识 */
::v-deep .leaflet-control-attribution,
.leaflet-control {
  display: none !important;
}

.tooltipInnerBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .icon {
    height: 30px;
    width: 30px;
  }
  .title {
    font-size: 16px;
    margin-left: 5px;
  }
}

.drawing-panel {
  position: absolute;
  right: 100px;
  top: 10px;
  height: 40px;
  width: 200px;
  background: "#fff";
  padding: 0px;
  z-index: 12;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightlist {
  margin-top: 10px;
  width: 244px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  .toplist {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f0f3f8;
    padding-bottom: 10px;

    .toptitle {
      margin: 10px 0;
      width: 72px;
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 25px;
    }

    .topcon {
      width: 224px;
      background: #f0f3f8;
      border-radius: 4px;
      display: flex;
      flex-direction: column;

      .topitem {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin: 6px 0;

        .switch {
          width: 30px;
        }

        .iconbox {
          width: 156px;
          height: 52px;
          background: #ffffff;
          border-radius: 4px;
          display: flex;
          justify-content: space-around;
          align-items: center;

          .iconimg {
            width: 32px;
            height: 32px;
          }

          .iconcatename {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #43496a;
            line-height: 16px;
          }
        }
      }
    }
  }

  .bottomlist {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #f0f3f8;
    padding-bottom: 10px;
    width: 100%;
    flex: 1;

    overflow-y: scroll;

    .bottomlistinner {
      // background: red;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 15px;
      margin-top: 15px;

      .btitle {
        height: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 17px;
      }

      .bitem {
        text-align: left;
        height: 20px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 17px;
        display: block;
      }
    }
  }
}

.container4 {
  width: 100%;
  height: 100%;
  color: #333;
  display: flex;
  flex-direction: row;
}

.leftmap {
  // display: flex;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
  margin-top: 10px;
  position: relative;

  .bmap-demo {
    flex: 1;
  }
}

.labelbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  border-radius: 5px;

  .labelcontent {
    border: solid 1px #fff;
    background: #fff;
    border-radius: 4px;
    color: #333;
    font-size: 14px;
    padding: 4px;
    display: flex;
    flex-direction: row;
    padding: 5px 10px;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 10px #06c;

    .iconimg {
      height: 30px;
      width: 30px;
    }

    .labeltext {
      margin-left: 10px;
    }

    // mar
  }

  .labeldown {
    margin-left: 15px;
    width: 0;
    height: 0;
    border-top: 10px solid #fff;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
  }
}
</style>