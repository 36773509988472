<template>
  <div class="page1">
   <headerPreview v-if="is_preview"></headerPreview>
   <Headers v-else ></Headers>
    <div class="detail">
      
 
      <div class="line3">
        <div class="locationame">{{tableData[0].locationname}}</div>
        
        <el-table
          :data="tableData"
          style="width: 1160px; margin-bottom: 0px"
          row-key="id"
          border
          class="custom-table" 
          :row-class-name="tableRowClassName"
        >
        <!-- <el-table-column label="序号" min-width="50" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="width: 100%;text-align: center">
              {{ scope.$index + 1 }}
            </div>
          </template>
        </el-table-column> -->
         
          <el-table-column prop="cname"  align="center" label="城市" min-width="60" show-overflow-tooltip> 
          </el-table-column>
          <!-- <el-table-column prop="zname" label="城区" min-width="80" show-overflow-tooltip> 
          </el-table-column> -->
          <!-- <el-table-column prop="qname" align="center"  label="区县市" min-width="60" show-overflow-tooltip> 
          </el-table-column> -->
          <el-table-column prop="qname" v-if="level_name!=='B'" align="center"  label="区县市" min-width="60" show-overflow-tooltip> 
          </el-table-column>

          <el-table-column prop="block" align="center" label="板块/街道" min-width="80" show-overflow-tooltip> 
          </el-table-column>
          <el-table-column prop="locationname" align="center" label="地块名称" min-width="180" show-overflow-tooltip> 
          </el-table-column>
          <el-table-column prop="location" align="center" label="地块位置" min-width="180" show-overflow-tooltip> 
          </el-table-column>
          <el-table-column prop="acreagemu" align="center" label="面积（亩）" min-width="70" show-overflow-tooltip> 
          </el-table-column>
          <el-table-column prop="useyears"  align="center" label="出让年限" min-width="80" show-overflow-tooltip> 
          </el-table-column>
          <el-table-column prop="usedetail" align="center" label="规划用途" min-width="80" show-overflow-tooltip> 
          </el-table-column>
          <el-table-column prop="volume" align="center" label="容积率" min-width="60" show-overflow-tooltip> 
          </el-table-column>
          <el-table-column prop="height" align="center" label="建筑高度" min-width="66" show-overflow-tooltip> 
          </el-table-column>
          <el-table-column prop="green" align="center" label="绿地率" min-width="60" show-overflow-tooltip> 
          </el-table-column>
          <el-table-column prop="status_txt" align="center" label="当前状态" min-width="80" show-overflow-tooltip> 
          </el-table-column>
        </el-table>
        <div class="btnbox">
          <img  @click="toSuggest" class="imgbtn" src="@/assets/collect/contactbtn.png" alt="">
        </div>
      </div>
      <div class="line4">
        <div class="leftline4">
           <el-carousel  style="width: 569px; height:320px" :interval="VideoInterval" :arrow="showArrow ? 'always' : 'never'"  >
            <el-carousel-item style="width: 569px; height:320px">
              <img class="hang"  :src="hangurl" alt="">
            </el-carousel-item>
            <el-carousel-item v-if="videoUrl!==''" style="width: 569px; height:320px">

               <video  class="video"
                  width="569"
                  height="320"
                  :poster="video_cover_url"
                  @play="videoPlay"
                  @pause="videoPause"
                  controls >
                  <source :src="videoUrl"  type="video/mp4">
                </video>         

            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="rightline4">
          <div class="r4left">
            <img  class="liang" src="@/assets/collect/liang.png" alt="">
          </div>
          <div class="r4right">
            <div class="r4top">地块亮点</div>
            <div class="r4bottom">{{highlight}}</div>
          </div>
        </div>
      </div>
      <div class="line5" v-if="panoramaUrl">
          <iframe :src="panoramaUrl" frameborder="no" width="1160" height="600" ></iframe>
      </div>
      <div class="line6">
        
      <!-- <leaflet-polygonpoint  :zoom="zoom" :center="center" :detail="detail"></leaflet-polygonpoint> -->
          <leaflet-aroundland :cate="1"  ></leaflet-aroundland>
      </div>




      <div class="line7" v-if="tableData[0].traffic||tableData[0].education||tableData[0].business||tableData[0].medical">
         <div class="r4top">周边配套</div>
         <div v-if="tableData[0].traffic" class="peitao peitao1">
          <div class="line"></div>
          <div class="peitaocon">
              <div class="title">交通配套</div>
              <div class="content">{{tableData[0].traffic}}</div>
          </div>
         </div>
         <div v-if="tableData[0].education" class="peitao peitao2">
          <div class="line"></div>
          <div class="peitaocon">
              <div class="title">教育资源</div>
              <div class="content">{{tableData[0].education}}</div>
          </div>
         </div>
         <div  v-if="tableData[0].business"  class="peitao peitao3">
          <div class="line"></div>
          <div class="peitaocon">
              <div class="title">商业配套</div>
              <div class="content">{{tableData[0].business}}</div>
          </div>
         </div>
         <div v-if="tableData[0].medical"  class="peitao peitao4">
          <div class="line"></div>
          <div class="peitaocon">
              <div class="title">医疗资源</div>
              <div class="content">{{tableData[0].medical}}</div>
          </div>
         </div>
         <div v-if="tableData[0].leisure"  class="peitao peitao5">
          <div class="line"></div>
          <div class="peitaocon">
              <div class="title">休闲配套</div>
              <div class="content">{{tableData[0].leisure}}</div>
          </div>
         </div>
         <div v-if="tableData[0].properties"  class="peitao peitao6">
          <div class="line"></div>
          <div class="peitaocon">
              <div class="title">周边楼盘</div>
              <div class="content">{{tableData[0].properties}}</div>
          </div>
         </div>

     
      </div>
 

      <div style="width: 100%; height: 20px"></div>
    </div>


  </div>
</template>

<script>
import Header from "@/components/collect/header.vue";
import headerPreview from "@/components/collect/headerPreview.vue";
import { landDetail } from "@/api/website/collect.js";
import {isgaode} from '@/settings.js'
// import leafletPolygonpoint from '@/components/leaflet/leafletPolygonpoint.vue';
// import leafletAround from '@/components/leaflet/leafletAround.vue';
import leafletAroundland from '@/components/leaflet/leafletAroundland.vue';
// import leafletAroundVue from '../../../components/leaflet/leafletAround.vue';
import { format } from 'echarts/core';
import ThumbsSwiperVue from '../../../components/swiper/ThumbsSwiper.vue';
export default {
  components: {
    Header,
    headerPreview,
    leafletAroundland
  },
  props:{
    is_preview:0
  },
  created() {
    this.id = this.$route.params.id
    this.city_id = this.$route.params.cityid
  },
  data() {
    return {
      showArrow:false,
      VideoInterval:5000,
      center: [],
			map: {},
			detail: {},
			zoom: 17,
      city_id:null,
			video_cover_url:'',
 level_name:'',
      hangurl:"",
      panoramaUrl:"https://www.720yun.com/vr/577j5zhnOk1",
      highlight: "该地块位于XX县XX区，地块编号为A-01，地块位置为东区种植园，地块面积为120.5亩，出让年限为5年，规划用途为粮食作物种植，容积率为500.0，建筑高度为1.2米，绿地率为10.0%。地块当前状态为正常使用。",
      tableData: [
       
      ],
    
    };
  },
  mounted() {
    this.getlandDetail()
  },
  methods: {
    videoPlay(){
      this.VideoInterval = 0
    },
    videoPause(){
        this.VideoInterval = 5000
    },
    getlandDetail() {
      landDetail({ id: this.id,city_id:this.city_id }).then((res) => {
        this.tableData = [res.data.table]
        this.level_name = res.data.level_name
        this.highlight = res.data.highlight
        this.panoramaUrl = res.data.cloud
        this.hangurl = res.data.hang_url
        this.videoUrl = res.data.video_url
        if(this.videoUrl){
          this.showArrow = true
        }
        this.video_cover_url = res.data.video_cover_url
      });

		},

    toSuggest(){
      //  this.$router.push({path:'/collect/suggest/'+this.tableData[0].id,})


        if(this.is_preview){
           window.open('#/preview/suggest/'+this.tableData[0].id+'/'+this.city_id, '_blank');
        }else{
          window.open('#/collect/suggest/'+this.tableData[0].id+'/'+this.city_id, '_blank');
        }
        
      
      
    },
    tableRowClassName(){
      return 'pointer-row'; // 为每一行添加自定义类名 
    },
     handleRowClick(row, event, column) {


      if(is_preview){
       
        this.$router.push({path:'/preview/landdetail/'+row.id,})
        }else{
          this.$router.push({path:'/collect/landdetail/'+row.id,})
        }
        
        
    },
  },
};
</script>

<style lang="scss" scoped>
.peitao{
  display: flex;
  margin-top:20px;
   margin-bottom:34px;
  .line{
    width: 5px;
    height: 42px;
    border-radius: 5px;
    background:#2F9A6B;
    margin-right:10px;
  }
  .peitaocon{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    .title{
      font-family: DingTalk JinBuTi, DingTalk JinBuTi;
      font-weight: 600;
      font-size: 18px;
      color: #2F9A6B;
    }
    .content{
      font-family: Microsoft YaHei UI, Microsoft YaHei UI;
      font-weight: 400;
      font-size: 16px;
      color: #000000;
    }
  }
}
.peitao1{
  .line{ 
    background:#2F9A6B; 
  }
  .peitaocon{
      .title{
        color: #2F9A6B;
      }
  }
}
.peitao2{
  .line{ 
    background:#EC8A00; 
  }
  .peitaocon{
      .title{
        color: #EC8A00;
      }
  }
}

.peitao3{
  .line{ 
    background:#5868E3; 
  }
  .peitaocon{
      .title{
        color: #5868E3;
      }
  }
}

.peitao4{
  .line{ 
    background:#77A300;
  }
  .peitaocon{
      .title{
        color: #77A300;
      }
  }
}

.peitao5{
  .line{ 
    background:#E23F1C; 
  }
  .peitaocon{
      .title{
        color: #E23F1C; 
      }
  }
}

.peitao6{
  .line{ 
    background:#0071CD; 
  }
  .peitaocon{
      .title{
        color: #0071CD;
      }
  }
}



  .line7{
    margin-top: 40px;
    width: 1160px;
    background: #F2FAFB;
    box-sizing: border-box;
    padding: 20px;

    border: 1px solid rgba(5, 159, 115, 1);
    // border-image: linear-gradient(180deg, rgba(5, 159, 115, 1), rgba(110, 199, 174, 1), rgba(255, 255, 255, 1)) 1 1;
    border-radius: 15px ;
    .r4top{
      display: flex;
      width:130px;
      height: 36px;
      background: url('../../../assets/collect/liangtitle.png') no-repeat ; 
      background-size:contain;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #FFFFFF;
    }
  
   
  }

 .line5{
   width:1160px;

   margin-top: 20px;
 }
 .line6{
  width:1160px;
   height: 660px;
    margin-top: 20px;
//  background: red;
 }
 .line4{
  margin-top: 10px;
  width:100%;
  display: flex;
  justify-content: space-between;
  .leftline4{
    width: 569px;
    height: 320px;
    border-radius: 0px 0px 0px 0px;
    img{
      width: 569px;
     height: 320px;
    
    }
  }
  .rightline4{
    width: 569px;
    height: 320px;
    border-radius: 0px 0px 0px 0px;
    background: linear-gradient(90deg, #DBEFF7 0%, #D7F4E5 70%);
    display: flex;
    .r4left{
      width: 32%;
      height: 100%;
      border-radius: 0px 0px 0px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 130px;
        height: 130px;
      }
    }
    .r4right{
      width: 68%;
      height: 100%;
      border-radius: 0px 0px 0px 0px;
      display: flex;
      flex-direction: column;
      .r4top{
        display: flex;
        width:164px;
        height: 48px;
        background: url('../../../assets/collect/liangtitle.png') no-repeat ;
        background-size:contain;
        margin-top: 20px;
        margin-bottom: 10px;
        font-size:22px;
        justify-content: center;
        align-items: center;
        color: #fff;
      }
      .r4bottom{
        width: 349px;
        height: 168px;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI;
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        overflow-y:auto;
      }
      .r4bottom{
 
        width: 349px;
        height: 168px;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI;
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        line-height: 24px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
      
    }
  }
}



.line3 {
    
  padding: 10px 0px;

  box-sizing: border-box;
  width: 100%; // 修改: 设置宽度为100%
  height: auto; // 修改: 设置高度为auto
 
}
.btnbox{
     margin:0;
     padding: 0;
     display: flex;
     width: 100%;
  .imgbtn{
 
      margin-top:20px;
      width: 100%;
      display: inline-block;
      height: 57px;
      border-radius: 21px 21px 21px 21px;
      overflow: hidden;
      cursor: pointer;
    }

}

.locationame{
     width: 100%;
    height: 30px;
    font-family: DingTalk JinBuTi, DingTalk JinBuTi;
    font-weight: 400;
    font-size: 30px;
    color: #009D70;
    line-height: 28px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    margin:16px 0;
}

.custom-table {
  ::v-deep .el-table__header-wrapper th {
    background-color: #009d70; // 设置表头颜色
    color: #ffffff; // 设置表头文字颜色
  }

  ::v-deep .el-table__header-wrapper .cell{
    padding-left:0;
    padding-right:0;
    padding:0;
    text-align: center;
    font-size: 16px;
  }



  ::v-deep .el-table__body-wrapper tr:hover {
    //  cursor: pointer; /* 设置鼠标样式为 pointer */
  }
   ::v-deep .el-table__body-wrapper .cell {
    padding-left:0;
    padding-right:0;
    font-size: 15px;
  }
   ::v-deep .el-table__body-wrapper  .el-table__cell{
    padding:10px 2px;
   }
   
  
}



::v-deep  .el-table__body-wrapper .cell{
  font-weight:bold;
  font-size:14px;
}

.page {

  background: linear-gradient(180deg, #e5f6f7 0%, #fafdfd 100%);
  border-radius: 0px 0px 0px 0px;
}


.detail {
  width: 1200px;
  padding:20px;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top:20px;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px rgba(180, 212, 198, 0.8);
  border-radius: 10px 10px 10px 10px;
}
::v-deep .el-carousel__arrow{
    font-size: 50px;
    font-weight: bold;
    height:60px;
    width: 60px;
}


 ::v-deep  .el-table__body-wrapper .el-table__cell{
  color:#000;
  padding:10px 2px;
  font-size:15px;
 }
 /*鼠标移入某行时的背景色*/
.el-table__row  tr:hover > td {
   background-color: #92cbf1;
}



 ::v-deep .el-table .el-table__cell{
  padding:4px  0;
 }

</style>